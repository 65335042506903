import {generatePhotoUrl} from "./photosHelper";

// Helper to create one array with unique elements from two different arrays
// Usage : arrayUnique(arrayOne.concat(arrayTwo));
export const arrayUnique = (mergedArray) => {
    let usersList = mergedArray.concat();
    for(let i=0; i<usersList.length; ++i) {
        for(let j=i+1; j<usersList.length; ++j) {
            if(usersList[i].id === usersList[j].id)
                usersList.splice(j--, 1);
        }
    }
    return usersList;
};

// Helper to generate and add url property to all photos and set main picture to be 0 array element
// Usage :
// 1. Get mainPhoto => let mainPhoto = loadMainPhotoHelper(allPhotos, 200, 200);
// 2. aggregatePhotos(mainPhoto, allPhotos)
export const aggregatePhotos = (mainPhoto, photos) => {
    photos.map((photo) => {
        return photo.url = generatePhotoUrl(photo, photo.crop, 200, null, 200);
    });
    photos.map((photo) => {
        return photo.bigUrl = generatePhotoUrl(photo, photo.crop, 1000, null, 1000);
    });
    for(let i=0; i<photos.length; i++) {
        if(photos[i].url === mainPhoto && i !== 0) {
            let main = photos.splice(i,1);
            photos.unshift(main[0]);
        }
    }
    return photos;
};

export const timeAgo = (timeStamp) => {
    let tDiff = Math.round((Date.now()-(timeStamp * 1000)) / 1000);

    if (tDiff >= 0 && tDiff < 60) {
        return 'Now';
    } else if (tDiff >= 60 && tDiff < 3600) {
        return Math.round(tDiff/60) + ' min';
    } else if (tDiff >= 3600 && tDiff < 86400) {
        return Math.round(tDiff/3600) + ' h';
    } else if (tDiff >= 86400 && tDiff < 604800) {
        return Math.round(tDiff/86400) + ' d';
    } else if (tDiff >= 604800 && tDiff < 2592000) {
        return Math.round(tDiff/604800) + ' wk';
    } else if (tDiff >= 2592000 && tDiff < 31536000) {
        return Math.round(tDiff/2592000) + ' mo';
    } else if (tDiff >= 31536000) {
        return Math.round(tDiff/31536000) + ' y';
    }
    return 'Now';
};

export const aggregateText = text => {
    text = escapeExpression(text);
    // Make links
    text = (text || "").replace(
        /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
        function(match, space, url){
            var hyperlink = url;
            if (!hyperlink.match('^https?:')) {
                hyperlink = 'http://' + hyperlink;
            }

            return space + '<a href="'+hyperlink+'" target="_blank" class="message-link">'+url+'</a>';
        }
    );

    // Replace new lines to br
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');

    return text;
};

export const aggregateAbout = text => {
    text = escapeExpression(text);
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return text;
};

// Handlebars escapeExpression function and constants
const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;

function escapeChar(chr) {
    return escape[chr];
}
function escapeExpression(string) {
    if (typeof string !== 'string') {
        if (string && string.toHTML) {
            return string.toHTML();
        } else if (string == null) {
            return '';
        } else if (!string) {
            return string + '';
        }
        string = '' + string;
    }

    if (!possible.test(string)) { return string; }
    return string.replace(badChars, escapeChar);
}

export const generateBrowserToken = () => {
    let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
    for (let i = 0; i < 20; i++) {
        let randomPoz = Math.floor(Math.random() * charSet.length);
        token += charSet.substring(randomPoz,randomPoz+1);
    }
    token = token.toUpperCase();
    let timestamp = new Date().getTime();
    let browserToken = "WEB-" + token + "-" + timestamp;
    localStorage.browserToken = browserToken;
    localStorage.browserInfoSent = false;
    return browserToken;
};