import inviteTravel from "../apis/inviteTravel";

export const loginUser = formValues => async dispatch => {
    await inviteTravel.post('/authentication/profile_login', formValues).then(response => {
        dispatch({ type: 'LOGIN_USER', payload: response });
    }).catch(err => {
        dispatch({ type: 'LOGIN_USER', payload: err.response });
    });
};

export const userFacebookAuth = (accessToken) => async dispatch => {
    let data = {accessToken: accessToken};
    const response = await inviteTravel.post('/authentication/facebook', data);
    if (response.data.status === 'registered' && window.fbq !== undefined && window.gtag !== undefined) {
        window.fbq('track', 'CompleteRegistration');
        window.gtag('event', 'conversion', {'send_to': 'AW-974194908/3FV3COmmm9kBENyRxNAD'});
        window.gtag('event', 'SignUp');
    }
    dispatch({ type: 'LOGIN_FACEBOOK_USER', payload: response});
};

export const userProfileRegistration = (data) => async dispatch => {
    const response = await inviteTravel.post('/authentication/profile_registration', data);
    return response.data;
}

export const userActivateRegistration = (uid, code) => async dispatch => {
    await inviteTravel.get('/authentication/profile_activation?uid=' + uid + '&code=' + code).then(response => {
        if (window.fbq !== undefined && window.gtag !== undefined) {
            window.fbq('track', 'CompleteRegistration');
            window.gtag('event', 'conversion', {'send_to': 'AW-974194908/3FV3COmmm9kBENyRxNAD'});
            window.gtag('event', 'SignUp');
        }
        dispatch({ type: 'USER_ACTIVATE_REGISTRATION', payload: response});
    }).catch(e => {
        if (e.response.status > 201) {
            window.location.replace('/app/login')
        }
    });
};

export const logoutUser = () => dispatch => {
    dispatch({ type: 'LOGOUT_USER'});
};

export const loggedUserData = (userId) => async dispatch => {
    const response = await inviteTravel.get('/users/' + userId);
    dispatch({ type: 'FETCH_USER', payload: response.data });
};

export const getGuestGallery = () => async dispatch => {
    const response = await inviteTravel.get('/gallery_guest');
    return response.data;
};

export const galleryData = (
    userId,
    isSearch = null,
    offset = 0,
    limit = 0,
    countryCode = null,
    lat = null,
    lon = null,
    placeId = null,
    px1 = null,
    px2 = null,
    py1 = null,
    py2 = null,
    searchType = null
) => async dispatch => {
    const response = await inviteTravel.get(
        'gallery/' + userId +
        '?countryCode=' + countryCode +
        '&lat=' + lat +
        '&limit=' + limit +
        '&lon=' + lon +
        '&offset=' + offset +
        '&placeId=' + placeId +
        '&px1=' + px1 +
        '&px2=' + px2 +
        '&py1=' + py1 +
        '&py2=' + py2 +
        '&searchType=' + searchType
    );
    response.data.isSearch = isSearch;
    dispatch({type: 'LOAD_GALLERY', payload: response.data});
};

export const galleryUser = (params) => async dispatch => {
    const response = await inviteTravel.get('/gallery/' + params.userId + '/' + params.participantId);
    dispatch({ type: 'FETCH_GALLERY_USER', payload: response.data });
};

export const fetchConversations = (userId, lastMessageId = null) => async dispatch => {
    const response = await inviteTravel.get('/conversations/' + userId + '?lastMessageId=' + lastMessageId);
    dispatch({type: 'FETCH_CONVERSATIONS', payload: response.data});
};

export const privateConversation = (params) => async dispatch => {
    if (params.userId && params.participantId) {
        const response = await inviteTravel.get('/conversations/' + params.userId + '/' + params.participantId);
        dispatch({type: 'FETCH_PRIVATE_CONVERSATION', payload: response.data});
    }
};

export const privateConversationLoadMore = (params, lastMessageId) => async dispatch => {
    if (params.userId && params.participantId) {
        const response = await inviteTravel.get(
            '/messages/' + params.userId + '/' +params.participantId + '?lastMessageId=' + lastMessageId
        );
        dispatch({type: 'FETCH_PRIVATE_CONVERSATION_LOAD_MORE', payload: response.data});
    }
}

export const markConversationAsRead = params => async dispatch => {
    const response = await inviteTravel.get('/conversations/' + params.userId + '/' + params.participantId+ '/read');
    if (response.status === 200) {
        dispatch({type: 'MARK_CONVERSATION_AS_READ', payload: params.participantId});
    }
};

export const sendPrivateMessage = (params, formValues) => async dispatch => {
    let clientTime = new Date().getTime();
    let data = {content: formValues.content, sender: parseInt(params.userId), clientTime: clientTime};
    dispatch({type: 'SEND_USER_PRIVATE_MESSAGE', payload: data});
    await inviteTravel.post('/messages/' + params.userId + '/' + params.participantId, {
        content: formValues.content,
        clientTime: clientTime
    });
};

export const likeUserProfile = (userId, participantId) => async dispatch => {
    const response = await inviteTravel.get('/likes/' + userId + '/' + participantId + '/like');
    if (response.data.like === true) {
        dispatch({type: 'LIKE_USER_PROFILE', payload: response})
    }
};

export const unlikeUserProfile = (userId, participantId) => async dispatch => {
    const response = await inviteTravel.get('/likes/' + userId + '/' + participantId + '/unlike');
    dispatch({type: 'UNLIKE_USER_PROFILE', payload: response})
};

export const userDataLikes = (userId) => async dispatch => {
    const response = await inviteTravel.get('/likes/' + userId + '/users_liked_me');
    dispatch({type: 'USER_WHO_LIKED_ME', payload: response.data});
};

export const userDataLikesRead = (userId, participantId) => async dispatch => {
    await inviteTravel.get('/likes/' + userId + '/users_liked_me/read/' + participantId);
};

export const userDataViews = userId => async dispatch => {
    const response = await inviteTravel.get('/users/' + userId + '/views');
    dispatch({type: 'USER_WHO_VIEWED_ME', payload: response.data});
};

export const userDataViewRead = (userId, participantId) => async dispatch => {
    await inviteTravel.get('/users/'+ userId + '/read_view/' + participantId);
};

export const userDataMyLikes = (userId) => async dispatch => {
    const response = await inviteTravel.get('/likes/' + userId);
    dispatch({type: 'USER_MY_LIKES', payload: response.data});
};

export const userHideFromGallery = (userId) => async dispatch => {
    const response = await inviteTravel.post('/users/' + userId + '/hide_from_gallery');
    dispatch({type: 'USER_HIDE_FROM_GALLERY', payload: response});
};

export const userUnhideFromGallery = (userId) => async dispatch => {
    const response = await inviteTravel.post('/users/' + userId + '/unhide_from_gallery');
    dispatch({type: 'USER_UNHIDE_FROM_GALLERY', payload: response});
};

export const userRemoveProfile = (userId) => async dispatch => {
    const response = await inviteTravel.delete('/users/' + userId + '/remove');
    dispatch({type: 'USER_DELETE_ACCOUNT', payload: response.data});
};

export const userEditProfile = (userId, data) => async dispatch => {
    const response = await inviteTravel.post('/users/' + userId, data);
    dispatch({type: 'USER_EDIT_PROFILE', payload: response.data});
};

export const userSetMainPhoto = (userId, photoId, photo) => async dispatch => {
    const response = await inviteTravel.post('/photos/' + userId + '/set_main/' + photoId);
    response.photo = photo.url;
    dispatch({type: 'USER_SET_MAIN_PHOTO', payload: response});
};

export const userDeletePhoto = (userId, photoId, photo) => async dispatch => {
    const response = await inviteTravel.delete('/photos/' + userId + '/delete/' + photoId);
    response.photo = photo;
    dispatch({type: 'USER_DELETE_PHOTO', payload: response});
};

export const userUploadPhoto = (userId, data) => async dispatch => {
    await inviteTravel.post('/photos/' + userId + '/upload/file', data).then(response => {
        dispatch({type: 'USER_ADD_NEW_PHOTO', payload: response.data});
    }).catch(error => {
        let e = error.response.data.error.split('-').join(' ');
        alert(e);
    })
};

export const userEditProfileOnboarding = (userId, data) => async dispatch => {
    const response = await inviteTravel.post('/users/' + userId + '?firstProfileUpdate=true', data);
    dispatch({type: 'USER_EDIT_PROFILE', payload: response.data});
};

export const userVerificationPhotoStatus = (userId) => async dispatch => {
    const response = await inviteTravel.post('/verification/'+userId);
    dispatch({type: 'USER_VERIFICATION_STATUS', payload: response.data});
};

export const userVerificationPhotoUpload = (userId, data) => async dispatch => {
    const response = await inviteTravel.post('/verification/' + userId + '/upload/', data);
    dispatch({type: 'USER_VERIFICATION_UPLOAD', payload: response.data});
};

export const socketUnreadConversationIds = conversationId => dispatch => {
    dispatch({type: 'USER_SOCKET_UNREAD_CONVERSATION_ID', payload: conversationId});
};

export const socketConversation = conversation => dispatch => {
    dispatch({type: 'USER_SOCKET_UNREAD_CONVERSATION', payload: conversation});
};

export const socketNewMessage = message => dispatch => {
    dispatch({type: 'USER_SOCKET_NEW_MESSAGE', payload: message});
};

export const doesConversationExist = (userId, participantId) => async dispatch => {
    return await inviteTravel.get('/conversations/' + userId + '/' + participantId + '?preview=1').then(response => {
        return response.status;
    }).catch(err => {
        return err.response.status;
    });
};

export const removeIgnoreConversation = (userId, participantId, status) => async dispatch => {
    await inviteTravel.get('/conversations/' + userId + '/' + participantId + '/' + status).then((response) => {
        if (response.status === 200) {
            window.location.replace('/app/conversations/');
        }
    });
};

export const submitStripePayment = (user, token, discount = null) => async dispatch => {
    let discountCode = null;

    switch (discount) {
        case "15":
            discountCode = "discount_15";
            break;
        case "20":
            discountCode = "discount_20";
            break;
        case "25":
            discountCode = "discount_25";
            break;
        case "30":
            discountCode = "discount_30";
            break;
        default:
            break;
    }

    /*
    PLAN
    Kisunas request
    If discount, then no free trial. Change plan name
    Otherwise, send normal pricepoint name aggregated by backend
     */

    let postData = {
        "plan": (discountCode !== null) ? "monthly.59" : user.membershipStripe.pricepoint.name,
        "stripeToken": token,
        "discount": discountCode
    };

    return await inviteTravel.post('/stripe/' + user.id + '/new_payment', postData).then(response => {
        if (window.gtag !== undefined) {
            window.gtag('event', 'conversion', {'send_to': 'AW-974194908/4Pf7CMHi_dgBENyRxNAD'});
            window.gtag('event', 'Trial');
        }
        return response.status
    }).catch(err => {
        return err.response.status
    })
};

export const getStripeSubscriptionList = (userId) => async dispatch => {
    const response = await inviteTravel.get(`/payments/${userId}/paypal_subscriptions`);
    if (response.status === 200) {
        dispatch({type: 'USER_STRIPE_SUBSCRIPTION_LIST', payload: response.data});
    }
};

export const cancelPaypalSubscription = (userId, subId) => async dispatch => {
    return await inviteTravel.post(`/payments/${userId}/paypal_cancel`, {id: subId}).then(response => {
        if (response.status === 200) {
            window.location.reload();
        }
    }).catch(err => {
        alert(err.response);
    })
};

export const uploadVideo = (userId, data) => async dispatch => {
    const response = await inviteTravel.post('/video/' + userId + '/upload_commercial_video', data);
    if (response.status === 200) {
        alert("Thank you! Successfully uploaded!");
    }
};

export const generateMuxUpload = (userId) => async dispatch => {
  return await inviteTravel.get("/video/" + userId + "/mux_url_for_video_upload?type=commercial");
};

export const firstVisit = (data) => async () => {
    return await inviteTravel.post('/web/info', data);
};

export const getEmailNotifications = userId => async () => {
    const response = await inviteTravel.get('/users/' + userId + '/notifications');
    return response.data;
};

export const setEmailNotifications = (userId, data) => async () => {
    const response = await inviteTravel.post('/users/' + userId + '/notifications', data);
    return response.data;
};