import React from 'react';
import {Link} from "react-router-dom";
//import PaymentPopup from "../../stripe/PaymentPopup";
import PopPop from "react-poppop";
import PaymentButtons from "../partials/PaymentButtons";

class ConversationsMembershipPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {paymentPopupOpen: false, popupOpen: false, discount: {}};
        this._isMounted = true;
    }

    async componentDidMount() {
        if (this.props.fromConversation) {
            this.setState({popupOpen: true})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickCloseEvent = () => {
        if (this.props.onCloseGoBack) {
            window.location.replace("/app/conversations/")
        } else {
            this.props.closeMembershipPopup()
        }
    };

    render() {
        let participant = {};
        if (this.props.conversation !== undefined) {
            participant = this.props.conversation.conversation.user
        } else {
            participant = this.props.participant
        }
        return (
            <div>
                <PopPop
                    open={(this.props.fromConversation) ? this.state.popupOpen : this.props.popupOpen}
                    position="centerCenter"
                    closeBtn={true}
                    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                    onClose={() => this.onClickCloseEvent()}
                >
                    <div className="popup-area-margin">
                    <div className="popup-block">
                        <div className="popup-content-wrapper">
                            <div style={{fontSize: '22px', marginTop: '20px'}}>Only Travelers can</div>
                            <div style={{fontSize: '22px', fontWeight: 'bold'}}>initiate a conversation</div>
                            <div style={{padding: '30px 10px 14px 10px', display: 'flex', justifyContent:'center'}}>
                                <div>
                                    <img alt="Participant"
                                        className={`conversations-is-locked-popup-user-img`}
                                        src={this.props.user.photo}
                                    />
                                </div>
                                <div style={{lineHeight: '122px', verticalAlign: 'middle', color: '#c4d2db', fontSize: '24px'}}>
                                    <span style={{whiteSpace: 'nowrap'}}>- <i className="fas fa-ban"/> -</span>
                                </div>
                                <div style={{float: 'right'}}>
                                    <img
                                        alt="Participant"
                                        className={`conversations-is-locked-popup-user-img`}
                                        src={participant.photo}
                                    />
                                </div>
                            </div>
                            <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                                Upgrade to Traveler and start chatting
                                with <span style={{color: 'black'}}>{participant.name}</span> and others
                            </div>
                            <PaymentButtons />
                        </div>
                    </div>
                </div>
                </PopPop>
            </div>
        )
    }
}

export default ConversationsMembershipPopup;