import React from 'react';
import { connect } from 'react-redux';
import PopPop from 'react-poppop';

import imgTraveler from "../../../assets/traveler.png";
import PaymentButtons from "../partials/PaymentButtons";

class Membership extends React.Component {
    constructor(props) {
        super(props);

        this.state = {loading: true};
        this._isMounted = true;
    }

    async componentDidMount() {
        if (this._isMounted) {
            this.setState({loading: false})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    userCanBuyMembershipContent = () => {
        return (
            <div>
                <div className="popup-block">
                    <div className="membership-popup-title"><h1>Membership</h1></div>
                    <div className="popup-content-wrapper">
                        <div style={{fontSize: '22px', fontWeight: 'bold'}}>Upgrade your status</div>
                        <div style={{padding: '50px 30px 34px 30px'}}>
                            <img src={imgTraveler} style={{width: '100%'}} alt="Membership" /></div>
                        <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>Upgrade to Membership and
                            start chatting with others
                        </div>
                        <PaymentButtons />
                    </div>
                </div>
            </div>
        )
    };

    userHasBoughtMembershipContent = () => {
        return (
            <div className="popup-block">
                <div className="popup-content-wrapper">
                    <div style={{fontSize: '22px', fontWeight: 'bold'}}>Your status</div>
                    <div style={{padding: '50px 30px 34px 30px'}}>
                        <img src="/app/images/traveler.png" style={{width: '100%'}} alt="Membership"/></div>
                    {/*<div style={{fontSize: '18px', fontWeight: '600', padding: '15px 0'}}>You can now start !</div>*/}
                    <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>You can now
                        start chatting with others
                    </div>
                    <div style={{padding: '0'}}/>
                </div>
            </div>
        )
    };

    determinePopupContent = () => {
        if (this.props.user.subscriptions.self !== null || this.props.user.subscriptions.both !== null) {
            return this.userHasBoughtMembershipContent()
        } else {
            return this.userCanBuyMembershipContent()
        }
    };

    render() {
        return (
            <div>
                <PopPop
                    open={this.props.popupOpen}
                    position="centerCenter"
                    closeBtn={true}
                    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                    onClose={() => this.props.closePopupMembership()}
                >
                    {this.determinePopupContent()}
                </PopPop>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps
)(Membership);