import React from 'react';
import {Route, withRouter, Switch} from "react-router-dom";
import { connect } from 'react-redux';
import { loginUser, loggedUserData, socketUnreadConversationIds } from "../actions";
import Gallery from './pages/Gallery';
import Conversations from './pages/Conversations';
import Conversation from './pages/Conversation';
import Profile from './pages/Profile';
import Login from './pages/loginForm';
import userProfile from './pages/userProfile'
import Header from './Header';
import MyLikes from './pages/settings/MyLikes';
import Settings from './pages/settings/Settings';
import contactSupport from "./pages/settings/contactSupport";
import EditProfile from "./pages/settings/EditProfile";
import AccountSuspended from "./AccountSuspended";
import Verification from "./Verification";
import UploadPhoto from "./onboarding/UploadPhoto";
import Subscriptions from "./pages/settings/Subscriptions";
import {NoMatchPage} from "./NoMatchPage";
import GuestGallery from "./pages/GuestGallery";
import ProfileActivation from "./ProfileActivation";

import './scss/main.scss';

// Onboarding menu
import Onboarding from "./onboarding/Onboarding";
import OnboardingPhoto from "./onboarding/OnboardingPhoto";

//Socket
import {socket} from "./Socket";
import Video from "./pages/Video";

// Helper to save users first visit
import Visit from "./pages/partials/Visit";

let scrollPositions = [];

class App extends React.Component {
    state = {loaded: null, location: '', popupOpenAndroid: false, popupOpeniOs: false, bookmarkClosed: false};

    componentDidMount() {
        //
        if (this.props.login.userId) {
            this.props.loggedUserData(this.props.login.userId).then(() => this.setState({loaded: true}));
        }

        // Socket for unreadConversations
        socket.emit('startListening', {"eventType":"unreadConversationId"});
        socket.on('unreadConversationId', (c) => {
            if (this.props.userData.unreadConversationsIds.indexOf(c.id) < 0) {
                try {
                    this.props.socketUnreadConversationIds(c.id);
                } catch (e) {

                }
            }
        });

        // Scroll Restoration
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual'
        }
        let { pathname } = this.props.location;
        if (scrollPositions[pathname]) {
            window.scrollTo(0, scrollPositions[pathname]);
        }
        window.addEventListener("scroll", this.listener);
    }

    componentDidUpdate(prevProps) {
        // Update state on every location change to trigger componentDidUpdate once again and scroll the page
        if (this.state.location !== this.props.location.pathname) {
            this.setState({location: this.props.location.pathname});
        }
        const { pathname } = this.props.location;
        if (!scrollPositions[pathname]) {
            // never seen this location before
            window.scrollTo(0, 0);
        } else {
            // seen it
            window.scrollTo(0, scrollPositions[pathname]);
        }
    }

    // Listener for scrollY
    listener = () => {
        scrollPositions[this.props.location.pathname] = window.scrollY;
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listener);
    }

    appMenuHelper = () => {
        // Not logged user menu
        if (!this.props.login.userId && !this.props.login.token) {
            return (
                <Switch>
                    <Route path="/landing/gallery" exact component={GuestGallery} />
                    <Route path="/activation" component={ProfileActivation} />
                    <Login/>
                    <Route component={NoMatchPage} />
                </Switch>
                );
            // Account suspended
        } else if (this.state.loaded && this.props.userData.view.accountSuspended) {
            return (
                <Switch>
                    <Route path="/contact-support" component={contactSupport} />
                    <Route path="/" exact component={AccountSuspended} />
                    <Route component={NoMatchPage} />
                </Switch>
            )
            // Needs to verify && photos.length === 0
        } else if (this.state.loaded && this.props.userData.needsToVerify && this.props.userData.photos.length === 0) {
            return <Route path="/" component={UploadPhoto} />
            // Has photos but needs to verify
        } else if (this.state.loaded && this.props.userData.needsToVerify) {
            return (
                <Switch>
                    <Route path="/" exact component={Verification} />
                    <Route path="/contact-support" component={contactSupport} />
                    <Route component={NoMatchPage} />
                </Switch>
                )
            // No age or sex is === null
        } else if (this.state.loaded && (this.props.userData.age === null || this.props.userData.sex === null)) {
            return <Route path="/" exact component={Onboarding} />
            // Loaded but skipped uploading a photo
        } else if (this.state.loaded && this.props.userData.photos.length === 0) {
            return (
                    <div>
                        <nav id="nav" className="navigation">
                            <Header pathHelper={this.props} />
                        </nav>
                        <Switch>
                            <Route path="/" exact component={Gallery} />
                            <Route path="/conversations" component={Conversations} />
                            <Route path="/onboarding-photo" component={OnboardingPhoto} />
                            <Route path="/profile" component={Profile} />
                            <Route path="/login" component={Login} />
                            <Route path="/gallery/:participantId" component={OnboardingPhoto}/>
                            <Route path="/conversation/:participantId" component={Conversation}/>
                            <Route path="/likes" component={MyLikes} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/contact-support" component={contactSupport} />
                            <Route path="/edit-profile" component={EditProfile} />
                            <Route path="/membership" component={Subscriptions} />
                            <Route path="/video" component={Video} />
                            <Route component={NoMatchPage} />
                        </Switch>
                    </div>
            );
            // User is OK
        } else if(this.state.loaded) {
            return (
                <div>
                    <nav id="nav" className="navigation">
                        <Header pathHelper={this.props}/>
                    </nav>
                    <Switch>
                        <Route path="/" exact component={Gallery} />
                        <Route path="/conversations" component={Conversations} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/login" component={Login} />
                        <Route path="/gallery/:participantId" component={userProfile}/>
                        <Route path="/conversation/:participantId" component={Conversation}/>
                        <Route path="/likes" component={MyLikes} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/contact-support" component={contactSupport} />
                        <Route path="/edit-profile" component={EditProfile} />
                        <Route path="/membership" component={Subscriptions} />
                        <Route path="/video" component={Video} />
                        <Route component={NoMatchPage} />
                    </Switch>
                </div>
            );
        }
    };

    render() {
        return (
            <div>
                <Visit/>
                {this.appMenuHelper()}
                <div id="App-padd"/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        userData: state.user
    }
};


export default withRouter(connect(
    mapStateToProps,
    {loginUser, loggedUserData, socketUnreadConversationIds}
)(App));