import React from 'react';
import { connect } from 'react-redux';
import {loggedUserData, getStripeSubscriptionList, cancelPaypalSubscription} from "../../../actions";
import {Link} from "react-router-dom";

class Subscriptions extends React.Component {
    state = {loaded: false};

    componentDidMount() {
        // if user is not loaded -> load it
        if (this.props.user === undefined) {
            this.props.loggedUserData(this.props.userId)
        } else {
            this.props.getStripeSubscriptionList(this.props.userId).then(() => {
                this.setState({loaded: true});
            });
        }

    };

    renderNavigationWorkaround() {
        return (
            <nav id="nav" className="navigation">
                <div className="nav-wrapper pointer-navigation">
                    <Link to="/profile" style={{fontSize: '24px'}}>
                        <i className="icon-back"/>
                    </Link>

                    <div className="item-user" style={{zIndex: 3000}}>
                        <div className="item-user--wrapper">
                            <span className="subscriptions-nav-title"> Subscriptions </span>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

    aggregateTime = nextBilling => {
        let t = new Date(nextBilling * 1000);
        return `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()} ${t.getHours()}:${t.getMinutes()}`;
    };

    generateSubscriptionText = isCanceled => {
        if (isCanceled) {
            return <span>Is valid until:</span>
        } else {
            return <span>Next billing date:</span>
        }
    };

    onClickCancel = subId => {
        this.props.cancelPaypalSubscription(this.props.userId, subId);
    };

    renderCancelButton = subscription => {
        if (subscription.isCanceled) {
            return (
                <div>
                    <br/>
                    <div className="subscriptions--info-area--title"> Subscription status: </div>
                    <div className="subscriptions--info-area--value"> Canceled </div>
                </div>
            )
        } else {
            return (
                <div>
                    <button
                        className="subscriptions--info-area--cancel-button"
                        onClick={() => this.onClickCancel(subscription.id)}
                    >
                        Cancel
                    </button>
                </div>
            )
        }
    }

    renderSubscriptionList = subscriptions => {
        return subscriptions.map((sub) => {
            return (
                <div className="subscriptions--info-area" key={sub.id}>
                    <div className="subscriptions--image-area">
                        <img src={"/app/images/traveler.png"} alt="traveler-subscription"/>
                    </div>
                    <div className="subscriptions--info-area--title"> Purchase ID: </div>
                    <div className="subscriptions--info-area--value"> {sub.id} </div>
                    <br/>
                    <div className="subscriptions--info-area--title"> {this.generateSubscriptionText(sub.isCanceled)}</div>
                    <div className="subscriptions--info-area--value"> {this.aggregateTime(sub.nextBilling)}</div>
                    {this.renderCancelButton(sub)}
                </div>
            )
        })
    };

    render() {
        if (this.state.loaded && this.props.subscriptions !== undefined) {
            return (
                <div>
                    {this.renderNavigationWorkaround()}
                    <div className="subscriptions">
                        {this.renderSubscriptionList(this.props.subscriptions)}
                    </div>
                </div>
            )
        } else if (this.state.loaded && this.props.subscriptions === undefined) {
            return (
                <div>
                    {this.renderNavigationWorkaround()}
                    <div className="subscriptions tac">
                        You do not have any active subscriptions
                    </div>
                </div>
            )
        }

        return <div className="loader">Loading...</div>
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user,
        subscriptions: state.subscriptions
    }
};

export default connect(
    mapStateToProps,
    {loggedUserData, getStripeSubscriptionList, cancelPaypalSubscription}
)(Subscriptions);