import React from 'react';
import { connect } from 'react-redux';
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";

import {siteConfig} from "../../../config";
import inviteTravel from "../../../apis/inviteTravel";
const ppl = siteConfig.paypal;

const successStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
    justifyContent: 'center',
    background: '#31bf75',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '12px'
}


class PaymentButtons extends React.Component {
    state = {isPurchaseSuccess: false};

    render() {
        const pricepoint = this.props.user.membershipStripe.pricepoint;
        const {isTrial, amount, currency} = pricepoint;
        const userId = this.props.user.id;
        const subscriptionOptions = {plan_id: isTrial ? ppl.planIdTrial : ppl.planId, custom_id: userId}

        if (this.state.isPurchaseSuccess) {
            return <div style={successStyle}>
                <p><span role="img" aria-label="check" style={{fontSize: '24px'}}>✅</span></p>
                <span>Your subscription successful!</span>
            </div>;
        }

        console.log(ppl, subscriptionOptions);

        return (
            <>
                {isTrial ? (
                    <div className={"price"} style={{padding: '10px 0'}}>
                        <h3>Start Your 3-day Free Trial</h3>
                        {amount} {currency} a month after trial ends.<br/>
                    </div>
                ): (<div className="price" style={{padding: '10px 0'}}>{amount} {currency} a month</div>)}

                <PayPalScriptProvider options={{"client-id": ppl.clientId, intent: "subscription", vault: true}}>
                    <PayPalButtons
                        createSubscription={(data, actions) => actions.subscription.create(subscriptionOptions)}
                        onApprove={(data, actions) => {
                            inviteTravel.post(`/payments/${userId}/paypal_purchase`, data);
                            this.setState({isPurchaseSuccess: true});
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }}
                        style={{shape: "pill"}}
                    />
                </PayPalScriptProvider>

                <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                    Plan automatically renews monthly.<br/> You can cancel any time.
                </div>
            </>
        )
    };
};

const mapStateToProps = state => {
    return {user: state.user}
};

export default connect(mapStateToProps)(PaymentButtons);